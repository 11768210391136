<template>
  <v-menu
    v-model="dataOpen"
    offset-y
  >
    <template v-slot:activator="{ on }">
      <v-btn
        v-on="on"
        fab
        x-small
        color="primary"
        class="ml-4"
      >
        <icon-tooltip
          bottom
          :disabled="dataOpen"
          icon-name="i.Email"
          :tooltip-text="$t('t.Messages')"
        />
      </v-btn>
    </template>
    <v-card>
      <v-list>
        <v-list-item
          v-if="$store.getters.currentUserHasPermission('UnassignedMessages')"
          @click="open('messages-unassigned')"
        >
          <v-icon class="mr-4">{{$icon('i.MessagesUnassigned')}}</v-icon>
          <v-list-item-content>{{$t('t.MessagesUnassigned')}}</v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="$store.getters.currentUserHasPermission('InboxAndSentMessage')"
          @click="open('messages-inbox')"
        >
          <v-icon class="mr-4">{{$icon('i.MessagesInbox')}}</v-icon>
          <v-list-item-content>{{$t('t.MessagesInbox')}}</v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="$store.getters.currentUserHasPermission('InboxAndSentMessage')"
          @click="open('messages-outbox')"
        >
          <v-icon class="mr-4">{{$icon('i.MessagesOutbox')}}</v-icon>
          <v-list-item-content>{{$t('t.MessagesOutbox')}}</v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { GlobalSearch } from '@/pages/search/controllers'
export default {
  components: {
    IconTooltip: () => import('@/components/icon-tooltip')
  },
  data () {
    return {
      dataOpen: false
    }
  },
  methods: {
    open (type) {
      this.$router.push('/search')
        .catch(e => {
          if (e.name === 'NavigationDuplicated') {
            Promise.resolve()
          } else { throw e }
        })
        .then(() => GlobalSearch.activeSearch.setDocumentType(type).execute(true))
    }
  }
}
</script>
